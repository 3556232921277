import { Box, Stack, ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";
import NavBar from "../main/navbar/Navbar";
import Dashboard from "../main/dashboard/Dashboard";
import RightBar from "../main/rightbar/RightBar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import PPNavigation from "../main/ppNavigation/PPNavigation";

const SaltexDashboard = () => {
    const [mode, setMode] = useState("light");
    const darkTheme = createTheme({
      palette:{
        mode: mode
      }
    });
    return (
      <ThemeProvider theme={darkTheme}>
      <Box>
        <NavBar />
        <Box className="w-full flex">
          <PPNavigation />
          <Dashboard />
          {/* <RightBar /> */}
        </Box>
        {/* <AddTooltip /> */}
      </Box>
      </ThemeProvider>
    );
}

export default SaltexDashboard;