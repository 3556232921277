
import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import PPNavList from "./PPNavList";

const PPNavigation = () =>{
    const { t } = useTranslation();
    /* const user = JSON.parse(localStorage.getItem("user"));
    const appUserName = user.displayName;
    const appUserOrg = user.org; */
    return(
        <Box flex={1} p={2} sx={{minWidth:'250px', display:{ xs:"none", sm:"block"}}} className='h-full lg:h-screen bg-slate-200'> 
        <Avatar sx={{width:100, height:100, margin:'auto'}} src="https://images.pexels.com/photos/3862607/pexels-photo-3862607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
            <Box className='mb-4 mt-4'>
                
                <h6 className='text-center text-slate-500 text-xl'>
                    {/* {appUserName} */}
                </h6>
                <Typography className='text-center text-slate-500'>
                    {/* {appUserOrg} */}
                </Typography>
            </Box>
            <PPNavList />
            
        </Box>
    )
}

export default PPNavigation;