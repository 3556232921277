import { createTheme } from "@mui/material";

export const theme = createTheme({
    // palette:{
    //     primary:{

    //     }
    // ,myColor:{
        
    // }
    // }
});