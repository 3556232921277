import { styled, useTheme } from '@mui/material/styles';
import { Notifications } from "@mui/icons-material";
import { AppBar, Avatar, Badge, Box, Divider, Drawer, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import PPNavList from '../ppNavigation/PPNavList';
import logo from '../../images/paga.png';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

const drawerWidth = 240;

const StyledToolbar = styled(Toolbar)({
    display:"flex",
    justifyContent: "space-between"
});

const Search =styled("div")(({theme}) => ({
    backgroundColor: "white",
    padding:"0, 10",
    borderRadius: theme.shape.borderRadius,
    width:"40%"
}));

const Icons  =styled("div")(({theme}) => ({
    display: "none",
    gap:"20px",
    alignItems:"center",
    [theme.breakpoints.up("sm")]:{
        display: "flex"
    }
}));

const UserBox  =styled("div")(({theme}) => ({
    display: "flex",
    gap:"10px",
    alignItems:"center",
    [theme.breakpoints.up("sm")]:{
        display: "none"
    }
}));

const StyledAppBar = styled(AppBar)({
    backgroundColor: "#FFF"
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const NavBar = () =>{
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [openD, setOpenD] = React.useState(false);

  const handleDrawerOpen = () => { 
    setOpenD(true);
  };

  const handleDrawerClose = () => {
    setOpenD(false);
  };

    return(
        <Box>
        <StyledAppBar position="sticky">
            <StyledToolbar>
                <img className="w-40" src={logo} alt="Logo" />
                <MenuIcon sx={{display:{xs:"block", sm:"none"}}} onClick={handleDrawerOpen}/>
                {/* <Search><InputBase placeholder="Search..."></InputBase></Search> */}
                <Icons>
                    <Avatar sx={{width:30, height:30}} src="https://images.pexels.com/photos/3862607/pexels-photo-3862607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" onClick={(e)=>setOpen(true)}/>
                </Icons>
                <UserBox onClick={(e)=>setOpen(true)}>
                    <Avatar sx={{width:30, height:30}} src="https://images.pexels.com/photos/3862607/pexels-photo-3862607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    <Typography variant="span">Marlon</Typography>
                </UserBox>
            </StyledToolbar>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                onClose={(e)=>setOpen(false)}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            >
                {/* <MenuItem >Profile</MenuItem>
                <MenuItem >My account</MenuItem>
                <MenuItem >Logout</MenuItem> */}
            </Menu>
        </StyledAppBar>
        <Drawer
            width = {drawerWidth} 
            variant="persistent"
            anchor="left"
            open={openD}
        >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <PPNavList />
      </Drawer>
      </Box>
    )
}

export default NavBar;