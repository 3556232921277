import { Box, Stack, ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavBar from "../main/navbar/Navbar";
import Dashboard from "../main/dashboard/Dashboard";
import RightBar from "../main/rightbar/RightBar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import PPNavigation from "../main/ppNavigation/PPNavigation";
import Cart from "../main/cart/Cart";
import { useLocation } from "react-router-dom";
import bgImage from '../images/support_system_bg_mafi_technologies.jpg';

const SaltexCart = () => {

    const [mode, setMode] = useState("light");
    const darkTheme = createTheme({
      palette:{
        mode: mode
      }
    });

    useEffect(() => {
      // console.log('en cart')
      // console.log(product)
    }, [])

    return (
      <ThemeProvider theme={darkTheme}>
      <Box className="h-full">
        <NavBar />
        <Box className="w-full flex h-full">
          <PPNavigation />
          <Box className="w-full h-full flex justify-center items-center" sx={{backgroundImage: 'url(' + bgImage + ')', backgroundSize: '100%'}}>
            <Cart />
            </Box>
          </Box>
        {/* <AddTooltip /> */}
      </Box>
      </ThemeProvider>
    );
}

export default SaltexCart;