
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';



import { Container, Typography, InputLabel, FormControl, Input, InputAdornment, Button, Grow, Box } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

import PPMain from './PPMain'
import styled from '@emotion/styled';



const defaultValues = {
    forgotEmail: ''
};

const ForgotPasswd = () => {
    const { t } = useTranslation();
    const [forgotEmail, setForgotEmail] = useState('');
    const [checked, setChecked] = React.useState(true);




    const authHandler = () =>{
        
    };

    const handleForgotEmail = (event) => {
        setForgotEmail(event.target.value);
    }

    const showLoginPasswd = () => {
        setNewForgotPopUp(
            false
        );
    }

    const SaltexLogo = styled('img')(({theme}) => ({
        [theme.breakpoints.up("sm")]:{
            width: '50%',
        },
        [theme.breakpoints.down("sm")]:{
            width: '40%',
        },
        p:20
    }));

    const forgotPasswdDivContent = <Box width={500} height={420} bgcolor="white" p={3} borderRadius={5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <Box justifyContent={'center'} alignItems={'center'}>
                                            <SaltexLogo src="assets/logos/logo_company_dark.png" alt="logo" />
                                            <Typography className="font-bold" margin={2} left={20}>
                                                {t('signIn-Welcome')}
                                            </Typography>
                                            <Typography className="mt10 font-bold" margin={2} left={20}>
                                                {t('forgot-passwd-label')}
                                            </Typography>
                                            <form onSubmit={authHandler} >
                                                <FormControl sx={{ m: 2, width: '85%', left:20 }} variant="standard">
                                                    <InputLabel htmlFor="forgotEmail">
                                                        Email
                                                    </InputLabel>
                                                    <Input
                                                    id="forgotEmail" 
                                                    autoFocus 
                                                    type='email' 
                                                    // error={!!forgotErrors.forgotEmail}
                                                    required 
                                                    value={forgotEmail} 
                                                    onChange={handleForgotEmail}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                        <AccountCircle />
                                                        </InputAdornment>
                                                    }
                                                    />
                                                </FormControl>

                                                <FormControl sx={{ width: '85%', left:15, margin: 2 }} variant="standard">
                                                    <Button
                                                        variant="outlined"
                                                        className=" w-full mt-16"
                                                        aria-label="Login"
                                                        type="submit"
                                                        size="large"  
                                                    >
                                                        {t('reset-passwd')}
                                                    </Button>
                                                </FormControl>

                                                <FormControl sx={{ width: '85%', left:15, margin: 2 }} variant="standard" className='pt20'>
                                                    <Button
                                                        variant="outlined"
                                                        className=" w-full mt-16"
                                                        aria-label="Login"
                                                        type="submit"
                                                        size="large"  
                                                        onClick={showLoginPasswd}
                                                    >
                                                        {t('back-to-login')}
                                                    </Button>
                                                </FormControl>
                                            </form>
                                        </Box>
                                    </Box>;
    
    const [newForgotPopUp, setNewForgotPopUp] = useState(true);

    return(
        <div>
            <Grow 
                in={checked}  
                style={{ transformOrigin: '0 0 0' }}
                {...(checked ? { timeout: 1000 } : {})}>
                    {newForgotPopUp ? forgotPasswdDivContent : <div><PPMain /></div>}
                </Grow>
        </div>
    );
}

export default ForgotPasswd;