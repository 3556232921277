import { Box, ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";
import NavBar from "../main/navbar/Navbar";
import AddTooltip from "../main/addTooltip/AddTooltip";


const SaltexListCompras = () => {

    const [mode, setMode] = useState("light");
    const darkTheme = createTheme({
      palette:{
        mode: mode
      }
    });

    return (
      <ThemeProvider theme={darkTheme}>
      <Box className="h-full">
        <NavBar />
        <Box className="w-full h-full flex justify-center items-start mt-4">
            <iframe
              title="Registro"
              src="https://lobby.impexpress.com/registro.php?sma=76"
              width="100%"
              height="100%"
            />
        </Box>
        {/* <AddTooltip /> */}
      </Box>
      </ThemeProvider>
    );
}

export default SaltexListCompras;